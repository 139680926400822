import React from 'react';
import Arrow from "../components/svg/arrow";
import Emoji from "../images/emoji.svg";
import Orange from "../components/svg/orange";


function Intro2() {

  return (
    <>
    <div className="intro2">
      {/* <div className="emojiBox">
      <img className="emoji" src={Emoji} alt=""/>
      <img className="emoji" src={Emoji} alt=""/>
      <img className="emoji" src={Emoji} alt=""/>
      
      </div>
      */}

      <div className="emojiBox">
        <div className="emoji"><Orange></Orange></div>
        <div className="emoji"><Orange></Orange></div>
        <div className="emoji"><Orange></Orange></div>
      </div>

      
      <div className="arrowBox"><Arrow degree="180"></Arrow></div>
    </div>
    </>
  );
};
export default Intro2;

