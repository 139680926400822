import React from 'react';
import CilrcleText from "./svg/circleText";
import Arrow from "./svg/arrow";


function GridProjet({isMobil}) {
  return (
    <>

      <a target="_blank" href="https://aliments.eu" className="list__row">
        <div className="list__row__imageBox">
          <Arrow degree="90"></Arrow>
          </div>
          <div className="list__row__textBox">
            <p>Aliments +++</p>
          </div>
        </a>


      <div className="list list--projet">
        <a target="_blank" href="https://2020.datagif.fr/" className="list__row border--right">
          <div className="list__row__imageBox">
          <Arrow degree="90"></Arrow>
          </div>
          <div className="list__row__textBox">
            <p>Vœux 2020 - Animation Generator +++</p>
            <span></span>
          </div>
        </a>

        

        <a target="_blank" href="https://lab.datagif.fr/tdb-2019/" className="list__row">
          <div className="list__row__imageBox">
          <Arrow degree="90"></Arrow>
          </div>
          <div className="list__row__textBox">
            <p>TDB - Saison 2019 +++</p>
            <span></span>
          </div>
        </a>

        <a target="_blank" href="https://tastefrance.datagif.fr/" className="list__row">
          <div className="list__row__imageBox">
          <Arrow degree="90"></Arrow>
          </div>
          <div className="list__row__textBox">
            <p>Taste France - Site pour présenter le projet +++</p>
            
          </div>
        </a>

      </div>
    </>
  );
};
export default GridProjet;

