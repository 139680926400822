import React from 'react';
import Emoji from "../images/emoji.svg";

function Footer() {

  return (
    <footer>
      <a href="mailto:info@christinastephan.com" className="link--button"><p>Contact</p></a> 
    </footer>
  );
};
export default Footer;

