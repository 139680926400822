import React from 'react';
import styled, { css } from 'styled-components';
import CilrcleText from "./svg/circleText";

import Arrow from "../components/svg/arrow";

const ProjectBox = styled.a`
    width: 41vw;
    height:100vh;
    overflow: hidden;
    /* @media (min-width: 768px) {
      min-width: 400px;
    } */
    @media (max-width: 768px) {
      width:100vw;
      height:auto;
      display:flex;
    }
`;


const ProjectContent = styled.div`
  height:auto;
  position:relative;
  @media (min-width: 768px) {
    width:100%;
    height:100%;
    position: absolute;
    top: 0%;
    left:0%;
  }
  @media (max-width: 768px) {
    width:100vw;
    display:flex;
    flex-direction:row-reverse;
    padding:10px;
    //padding-left: 22vw;
  }

  .textWrapper{
    @media (max-width: 768px) {
      width:calc(60vw - 10px);
      padding-left:10px;
    }
  }

  .imgWrapper{
    position:absolute;
    bottom:2vw;
    right:2vw;
    width:calc(80% - 4vw);
    height:50vh;
    overflow:hidden;
    @media (max-width: 768px) {
      width:40vw;
      height:40vw;
      bottom:auto;
      right:auto;
      position:relative;
    }
    img{
      width:100%;
      height:100%;
      object-fit:cover;
      transition: all 0.3s;
    }
  }
`;



function Project({titel,subtext,name,link}) {

  return (

    <>
   
      <ProjectBox className="project" href={link} target="_blank">
        <ProjectContent className="project__content">
          <div className="textWrapper">
       
          <h3><p>{titel}</p></h3>
          
          </div>
          
          <CilrcleText word={
            subtext==="Dev"?
            "*** Dev + Dev + Dev + Dev"
            :subtext==="Design + Dev"?
            "*** Design + Dev + Design + Dev"
            :subtext==="Progress"?
            "*** En progress +  En progress"
            :""
          } size="62"></CilrcleText>
          
          <div className="imgWrapper">
            <img src={name}></img>
          </div>
          
        </ProjectContent>
        
      </ProjectBox>
  
    </>
  );
};
export default Project;

