import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import Cote from '../images/bgCote.jpg';
import Card from '../images/bgCard.png';
import Shit from '../images/bgShit.svg';
import Lieux from '../images/bgLieux.png';
import Kap from '../images/bgCap.jpg';
import Social from '../images/bgSocial.png';
import Generator from '../images/bgGenerator.png';

import Project from "./Project";

import Arrow from "../components/svg/arrow";

//import scrollDirection from "./../function/scrollDirection"


function ProjectContent({isMobil,work}) {

  const [scrollDir, setScrollDir] = useState("down");

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? "down" : "up");
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll);
    console.log(scrollDir);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollDir]);


  return (
    <>

    {isMobil==="false"?
      <div className="project__intro">
        {scrollDir==="down"?
          <Arrow degree="90"></Arrow>:
          <Arrow degree="0"></Arrow>
        }
      </div>
      :
      ""
    }

      {work==="prive"?
      <>
        <Project titel="Lookbook Cotemer" subtext="Design + Dev" name={Cote} link="https://lookbook-cotemer.netlify.app/"></Project>

        <Project titel="Shit Happens Catalogue" subtext="Design + Dev" name={Shit} link="https://shithappens.netlify.app/"></Project>

        <Project titel="Coucou de la Bretagne" subtext="Design + Dev" name={Card} link="https://cartepostall.netlify.app/"></Project>

        <Project titel="Div-Police Generator" subtext="Design + Dev" name={Generator} link="https://div-font.netlify.app/"></Project>

        </>
        :
        ""
      }

      

      {isMobil==="false"?
        <div className="project__intro">
       
            {scrollDir==="down"?
              <Arrow degree="180"></Arrow>:
              <Arrow degree="270"></Arrow>
            }
  
        </div>
      :""}

    </>
  );
};
export default ProjectContent;

