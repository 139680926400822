import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import scrollVertical from "../function/scrollVercical.js";
import ProjectContent from "./ProjectContent";


const Container = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 3vw;
`;

const Spaceholder = styled.div`
    position: relative;
    width: 100%;
`;

const Sticky = styled.div`
  position: sticky;
  top: 0;
  height:100vh;
  width: 100%;
  overflow-x: hidden;
  //background-color: var(--main-color);
`;

const Horizontal = styled.div`
  position: absolute;
  height: 100%;
  will-change: transform;
`;

const ProjectWrapperBox = styled.div`
  position: relative;
  height: 100%;
  width:100%;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  @media (max-width: 768px) {
    flex-direction:column;
  }
`;



function ProjectWrapper({isMobil, work}) {

  useEffect(() => {
    if(!isMobil){
      scrollVertical()
    }else{}
  }, []);

  return (

    <>
      {console.log("wrapper "+ isMobil)}
      {isMobil==="true"?
        <ProjectWrapperBox role="feed" className="projects">
          <ProjectContent isMobil="true" work={work}></ProjectContent>
        </ProjectWrapperBox>
        :
        <Container className="observe container container--project">
          <Spaceholder className="space-holder">
            <Sticky className="sticky"> 
              <Horizontal className="horizontal">
                <ProjectWrapperBox role="feed" className="projects">
                  <ProjectContent isMobil="false" work={work}></ProjectContent>
                </ProjectWrapperBox>     
              </Horizontal>
            </Sticky>
          </Spaceholder>
        </Container>
      }
    </>
  );
};
export default ProjectWrapper;

