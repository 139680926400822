

import React from 'react';


function Arrow({degree}) {

  return (
    
    <svg className="arrow" width="389" height="510" viewBox="0 0 389 510" fill="none" xmlns="http://www.w3.org/2000/svg" 
    style={{transform:"rotate("+degree+"deg)"}}
    //style={{backgroundColor:"red"}}
    
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M0 195H106H121V510H267V195.5H283H389L194.5 1V0.5L0 195Z" fill="black"/>
    </svg>
  );
};
export default Arrow;

