import React from 'react';

function LoopHolder({text}) {

  return (
    <>
    <div className="loop-holder">
      <div className="loop-holder__text"><p>+++ {text}</p></div>
      <div className="loop-holder__text strong"><p>+++ {text}</p></div>
      <div className="loop-holder__text"><p>+++ {text}</p></div>
      <div className="loop-holder__text"><p>+++ {text}</p></div>
      <div className="loop-holder__text"><p>+++ {text}</p></div>
    </div>
    </>
  );
};
export default LoopHolder;

