import React from 'react';


function Grid({isMobil}) {
  return (
    <>
         <div className="agences grid grid--1">
        <div className="grid__label">
          <p>Expériences :</p>
        </div>
        <div  className="grid__content">
          <p>
            <a target="_blank" rel="noreferrer" href="https://datagif.fr/">Datagif</a>, <a target="_blank" rel="noreferrer" href="https://knr.paris/">knr Paris</a>, <a target="_blank" rel="noreferrer" href="https://g31.design/">g31</a>, <a target="_blank" rel="noreferrer" href="http://morgen.studio/">Morgen Gestaltung</a>, <a target="_blank" rel="noreferrer" href="https://rheingeist.de/">Rheingeist</a>, Freelance
          </p>
          </div>
      </div>

      <div className="agences grid grid--2">
        <div className="grid__label">
          <p>Compétences :</p>
        </div>
        <div  className="grid__content">
          <p>
            React, Wordpress, Animations web
          </p>
          <hr></hr>
          <p>
            Figma, 
            Sketch, Illustrator, Photoshop, InDesign, After Effects
          </p>
        </div>
      </div>


      <div className="grid grid--3">
        <div className="grid__label">
          <p>Certificat :</p>
        </div>
        <div  className="grid__content">
          <p>
            Opquast
          </p>
        </div>
      </div>

      <div className="grid grid--3">
        <div className="grid__label">
          <p>Formation :</p>
        </div>
        <div  className="grid__content">
          <p>
            Bachelor Web | Hetic 
          </p>
          <hr></hr>
          <p>
            Bachelor Communication Visuelle | HSD
          </p>
        </div>
      </div>




    </>
  );
};
export default Grid;

