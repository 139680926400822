import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import cursorAnimaton from "../function/cursorAnimaton.js";


const CursorIcon = styled.span`

  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 50px;
  height: 50px;
  margin: -25px 0 0 -25px;
  opacity: 0;
  transition: opacity .2s $ease-out-quart;
  pointer-events: none;

  .cursor-move-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    margin-top: -3px;
    margin-left: -3px;
  }

  .cursor-move-outer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .cursor-inner {
    display: block;
    width: 100%;
    height: 100%;
    //background: #484747;
    border-radius: 50%;
    border: 1px solid#484747;
    transition: transform .4s $ease-out-quint, opacity .4s $ease-out-quart;
  }

  .cursor-outer {
    display: block;
    width: 100%;
    height: 100%;
    border: 1px solid #484747;
    border-radius: 50%;
    box-sizing: border-box;
    transition: border .4s $ease-out-quart, transform .4s $ease-out-quint, opacity .4s $ease-out-quart;
  }
`;



function Cursor({}) {

  useEffect(() => {
    cursorAnimaton()
  }, []);

  return (

    <>
      <CursorIcon className="cursor">
        <span className="cursor-move-inner">
          <span className="cursor-inner"></span>
        </span>
        <span className="cursor-move-outer">
          <span className="cursor-outer"></span>
        </span>
      </CursorIcon>
    </>
  );
};
export default Cursor;

