import React, { useState, useEffect } from 'react';
import CilrcleText from "./svg/circleText";

import ProjectWrapper from "./ProjectWrapper";
import Cursor from "./Cursor";
import LoopHolder from './LoopHolder';
import Intro2 from './Intro2';
import Grid from './Grid';
import GridProjet from './GridProjet';
import Footer from './Footer';


function Home() {
  const [isMobil, setIsMobil] = useState(false);

 

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 768px)')
    // Check if the media query is true
    if (mediaQuery.matches) {
      // Then trigger an alert
      setIsMobil("false")
      console.log("desktop")
    }else{
      setIsMobil("true")
      console.log("mobil")
    } 
    console.log(isMobil)
  }, []);


  

  return (

    <>
    {console.log("home "+isMobil)}

    <Cursor></Cursor>
    <Intro2></Intro2>
    <main>
      <p className="textBox textBox--1"> 
        Hallo je suis Christina Stephan, développeuse front-end, <span>amoureuse de design</span> et toujours prête à ajouter <span>un petit extra</span> au web. <br></br><br></br>
        Actuellement je suis en dernière année à <span>Hetic</span> et je fais mon <span>alternance chez Datagif</span>.
      </p>

      <section className="section--workAgence">
        <LoopHolder text="Projets chez Datagif"></LoopHolder>
        <GridProjet></GridProjet>
      </section>
      
     
      

      <section className="section--workPrive">
        <p className="textBox textBox--2"> 
          Pour moi le web est un <span>grand terrain de jeu</span> où on repousse les limites du design statique. J'aime passer mon temps sur codepen pour <span>experimenter</span>.<br></br>
          <a href="https://codepen.io/christinastep" className="link--button"><p>Codepen</p></a>
          {isMobil==="false"?
          <CilrcleText word="***** Uiii, C'etait pas prévu mais c'est chouette." size="102"></CilrcleText>
          :""
          }
          
        </p>
      
        <LoopHolder text="Projets"></LoopHolder>
        <ProjectWrapper isMobil={isMobil} work="prive"></ProjectWrapper>

        <p className="textBox textBox--3"> 
          Voilà, c'était la sélection des mes travaux web. J'espère vous avez bien aimé.<br></br><br></br>
          <CilrcleText word="***** Bisous. Bisous. Bisous. Bisous. Bisous." size="102"></CilrcleText>
        </p>
      </section>
      

      <LoopHolder text="Information"></LoopHolder>
      <Grid></Grid>

    </main>
    <Footer></Footer>
    </>
  );
};
export default Home;

