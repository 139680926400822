

import React from 'react';


function Orange() {

  return (
    
    <svg className="orange" width="2420" height="2420" viewBox="0 0 2420 2420" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1210.5 1225L1074.97 306.25L1346.03 306.25L1210.5 1225Z" fill="black"/>
<circle cx="1210.5" cy="296.5" r="135.5" fill="black"/>
<path d="M1209.5 1195L1345.03 2113.75L1073.97 2113.75L1209.5 1195Z" fill="black"/>
<circle cx="1209.5" cy="2123.5" r="135.5" transform="rotate(-180 1209.5 2123.5)" fill="black"/>
<path d="M1195 1210.5L2113.75 1074.97L2113.75 1346.03L1195 1210.5Z" fill="black"/>
<circle cx="2123.5" cy="1210.5" r="135.5" transform="rotate(90 2123.5 1210.5)" fill="black"/>
<path d="M1225 1209.5L306.25 1345.03L306.25 1073.97L1225 1209.5Z" fill="black"/>
<circle cx="296.5" cy="1209.5" r="135.5" transform="rotate(-90 296.5 1209.5)" fill="black"/>
<path d="M1199.75 1220.96L1753.57 475.469L1945.24 667.142L1199.75 1220.96Z" fill="black"/>
<circle cx="1856.3" cy="564.411" r="135.5" transform="rotate(45 1856.3 564.411)" fill="black"/>
<path d="M1220.25 1199.04L666.435 1944.53L474.763 1752.86L1220.25 1199.04Z" fill="black"/>
<circle cx="563.705" cy="1855.59" r="135.5" transform="rotate(-135 563.705 1855.59)" fill="black"/>
<path d="M1199.04 1199.75L1944.53 1753.57L1752.86 1945.24L1199.04 1199.75Z" fill="black"/>
<circle cx="1855.59" cy="1856.3" r="135.5" transform="rotate(135 1855.59 1856.3)" fill="black"/>
<path d="M1220.96 1220.25L475.469 666.435L667.142 474.762L1220.96 1220.25Z" fill="black"/>
<circle cx="564.412" cy="563.704" r="135.5" transform="rotate(-45 564.412 563.704)" fill="black"/>
</svg>

  );
};
export default Orange;

