import React from 'react';
import Orange from "./orange";


function CircleText({word,size}) {

  return (

    <div className={"circle "+size}>
      <svg className="circle__circle" width={size} height={size} viewBox={"0 0 "+size+" "+size} preserveAspectRatio="xMidYMid">
        <defs>
          <path id={"circlePath"+size} d={"M "+size/2+", "+size/2+" m -"+size/2.6+", 0 a "+size/2.6+","+size/2.6+" 0 1,0 "+size/1.3+",0 a "+size/2.6+","+size/2.6+" 0 1,0 -"+size/1.3+",0"}/>
        </defs>
        <circle cx={size/2} cy={size/2} r={size/2} fill="none"/>
        <g>
            <use xlinkHref={"#circlePath"+size} fill="none"/>
            <text fill="#000">
                <textPath xlinkHref={"#circlePath"+size}>{word}</textPath>
            </text>
        </g>
      </svg>
      <div className="circle__smilie"><Orange></Orange></div>

      
      {/* <svg className="circle__smilie" width="465" height="465" viewBox="0 0 465 465" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
        <path d="M232.2 464.5C104.2 464.2 0.100072 360.4 7.17106e-05 232.8C-0.0999283 104.3 104.4 -0.399834 232.5 0.000165768C360.8 0.400166 464.4 104.2 464.5 232.4C464.5 360.2 359.9 464.7 232.2 464.5ZM232.4 435.2C344.5 434.9 435.2 344.1 435.2 232.1C435.2 120 343.8 28.8002 231.8 29.2002C119.7 29.6002 29.1001 120.6 29.2001 232.5C29.4001 344.5 120.7 435.5 232.4 435.2Z" fill="black"/>
        <path d="M222.6 375.7C175.5 373 133.2 349.4 104.2 302.6C100.7 297 100.4 291.2 104.3 285.8C107.9 280.7 113.1 278.9 119.2 280C124.2 280.9 127.2 284.3 129.7 288.4C146.8 316.2 171 334.5 202.5 342.8C253.1 356.1 306.3 334.4 334.1 289.4C336.7 285.2 339.4 281.3 344.6 280.1C350.6 278.8 355.9 280.3 359.8 285.2C363.6 290.1 364.4 295.8 361 301.1C355.7 309.3 350.3 317.6 343.9 325C315.3 358 273.7 376.3 222.6 375.7Z" fill="black"/>
        <path d="M188.6 173.3C188.5 189.6 175.7 202.3 159.4 202.2C143.4 202.1 130.5 189.2 130.5 173.4C130.4 157.1 143.7 144.1 160.1 144.2C176.1 144.4 188.7 157.2 188.6 173.3Z" fill="black"/>
        <path d="M275.8 173.4C275.8 157.1 288.6 144.2 304.8 144.3C321 144.3 334 157.4 333.9 173.5C333.8 189.4 320.9 202.3 305 202.4C288.7 202.4 275.8 189.6 275.8 173.4Z" fill="black"/>
        </g>
        <defs>
        <clipPath id="clip0">
        <rect width="464.4" height="464.5" fill="white"/>
        </clipPath>
        </defs>
        </svg> */}
    </div>

  );
};
export default CircleText;

